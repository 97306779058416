import { Box, Card, styled } from '@mui/material';

export const PageHeader = styled(Box)`
    background-image: url('/images/header-background.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    width: 100%;
`;

export const Greeter = styled(Box)`
    color: white;
    display: flex;
    align-items: center;
`;

export const StyledCard = styled(Card)`
    padding: 4px 8px;
    width: 100%;
    display: block;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
`;