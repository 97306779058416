import { SecuredLayout } from '../Layout/SecuredLayout';
import { Box, Chip, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { StyledCard } from '../Layout/styles';
import SendNotification from './SendNotification';
import { EmployeeDetails } from '../../@types/employeeData';
import { useEffect, useState } from 'react';
import { useAuth, useProvideSnackBar } from '../../hooks';
import { DataGridPro, getGridDateOperators, getGridStringOperators, GridColDef, huHU } from '@mui/x-data-grid-pro';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import huLocale from 'date-fns/locale/hu';
import ErrorBoundary from '../../hooks/errorBoundary';
import { format } from 'date-fns';

const WORKER_TRANSPORT_API_URL = (window as any).WORKER_TRANSPORT_API_URL;

const columns: GridColDef[] = [
    {
        field: 'workplace',
        headerName: 'Munkahely',
        width: 170,
        sortable: true,
        headerAlign: 'left',
        align: 'left',
        renderHeader: () => <strong>Munkahely</strong>,
        filterOperators: getGridStringOperators().filter(({ value }) => ['contains'].includes(value)),
    },
    {
        field: 'operationUnit',
        headerName: 'Üzemegység',
        width: 230,
        sortable: true,
        headerAlign: 'left',
        align: 'left',
        renderHeader: () => <strong>Üzemegység</strong>,
        filterOperators: getGridStringOperators().filter(({ value }) => ['contains'].includes(value)),
    },
    {
        field: 'line',
        headerName: 'Járat',
        width: 170,
        sortable: true,
        headerAlign: 'left',
        align: 'left',
        renderHeader: () => <strong>Járat</strong>,
        filterOperators: getGridStringOperators().filter(({ value }) => ['contains'].includes(value)),
    },
    {
        field: 'stop',
        headerName: 'Megálló',
        width: 230,
        sortable: true,
        headerAlign: 'left',
        align: 'left',
        renderHeader: () => <strong>Megálló</strong>,
        filterOperators: getGridStringOperators().filter(({ value }) => ['contains'].includes(value)),
    },
    {
        field: 'employeeName',
        headerName: 'Dolgozó neve',
        width: 230,
        sortable: true,
        headerAlign: 'left',
        align: 'left',
        renderHeader: () => <strong>Dolgozó neve</strong>,
        filterOperators: getGridStringOperators().filter(({ value }) => ['contains'].includes(value)),
    },
    {
        field: 'employeeID',
        headerName: 'Dolgozó azonosító',
        width: 200,
        sortable: true,
        headerAlign: 'left',
        align: 'left',
        renderHeader: () => <strong>Dolgozó azonosító</strong>,
        filterOperators: getGridStringOperators().filter(({ value }) => ['contains'].includes(value)),
    },
    {
        field: 'arrivalTime',
        headerName: 'Megállóba érkezés ideje',
        width: 230,
        sortable: true,
        headerAlign: 'left',
        align: 'left',
        type: 'dateTime',
        headerClassName: 'headerName',
        renderHeader: () => <strong>Megállóba érkezés ideje</strong>,
        filterOperators: getGridDateOperators(true).filter(({ value }) => ['onOrAfter', 'onOrBefore'].includes(value)),
        valueFormatter: params => {
            return format(new Date(params.value as string), 'yyyy.MM.dd HH:mm:ss');
        },
    },
    {
        field: 'shift',
        headerName: 'Műszak',
        width: 160,
        sortable: true,
        headerAlign: 'left',
        align: 'left',
        renderHeader: () => <strong>Műszak</strong>,
        filterOperators: getGridStringOperators().filter(({ value }) => ['contains'].includes(value)),
    },
    {
        field: 'language',
        headerName: 'Nyelv',
        width: 80,
        sortable: true,
        headerAlign: 'left',
        align: 'left',
        renderHeader: () => <strong>Nyelv</strong>,
        filterOperators: getGridStringOperators().filter(({ value }) => ['contains'].includes(value)),
    },
];

export default function WorkerTravelDetailsPage() {
    const { user } = useAuth();
    const { showError, showResponseError } = useProvideSnackBar();
    const [employeeData, setEmployeeData] = useState<EmployeeDetails[]>([]);
    const [tokensFromWorkerList, setTokensFromWorkerList] = useState<string[]>([]);
    const [tokensByCompany, setTokensByCompany] = useState<Record<string, string[]>>({});
    const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);
    const [registrationTokenSet, setRegistrationTokenSet] = useState<Set<string>>(new Set<string>());
    const [loading, setLoading] = useState<boolean>(false);

    const fetchGetWorkerTravelDetails = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${WORKER_TRANSPORT_API_URL}/v1/app-user/`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
            });
            if (response.ok) {
                const existingDetails: EmployeeDetails[] = await response.json();
                setEmployeeData(existingDetails);
            } else {
                await showResponseError(response);
            }
        } catch (error: any) {
            showError('Hiba a szerver elérésében! ' + error.message);
        }
        setLoading(false);
    };

    const getRegistrationTokensByCompany = async () => {
        try {
            const response = await fetch(`${WORKER_TRANSPORT_API_URL}/v1/app-user/appusers-by-company`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                mode: 'cors',
            });
            if (response.ok) {
                const tokens = await response.json();
                setTokensByCompany(tokens);
            } else {
                await showResponseError(response);
            }
        } catch (error: any) {
            showError('Hiba a szerver elérésében! ' + error.message);
        }
    };

    useEffect(() => {
        fetchGetWorkerTravelDetails();
        getRegistrationTokensByCompany();
    }, []);

    useEffect(() => {
        const tokenSet = new Set<string>();
        selectedCompanies.forEach(company => tokensByCompany[company]?.forEach(token => tokenSet.add(token)));
        tokensFromWorkerList.forEach(token => tokenSet.add(token));
        setRegistrationTokenSet(tokenSet);
    }, [selectedCompanies, tokensFromWorkerList]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={huLocale}>
            <SecuredLayout>
                <Grid display={'flex'} flexDirection={'column'} px={{xs: 1, md: 2}} pt={1} sx={{ justifyContent: 'center' }}>
                    <Grid container display={'flex'} spacing={4} justifyContent={'space-between'}>
                        <Grid display={'flex'} item xs={12} md={7}>
                            <SendNotification
                                registrationTokens={registrationTokenSet}
                                setRegistrationTokens={setRegistrationTokenSet}
                                setSelectedCompanies={setSelectedCompanies} />
                        </Grid>
                        <Grid item xs={12} md={5} display={'flex'}>
                            <StyledCard>
                                <Box>
                                    <Typography variant={'h5'} p={1} pb={2}>Partnerek {selectedCompanies.length > 0 && `(${selectedCompanies.length} kiválasztva)`}</Typography>
                                    {Object.entries(tokensByCompany).map(([company, tokens]) => (
                                        <Chip
                                            key={company}
                                            label={`${company} (${tokens.length} fő)`}
                                            size="small"
                                            variant="filled"
                                            color={selectedCompanies.includes(company) ? 'primary' : 'default'}
                                            sx={{ margin: 0.25, p: 0.5 }}
                                            onClick={() => {
                                                setSelectedCompanies(prevSelected => prevSelected.includes(company) ?
                                                    prevSelected.filter(c => c !== company)
                                                    :
                                                    [...prevSelected, company],
                                                );
                                            }}
                                        />
                                    ))}
                                </Box>
                            </StyledCard>
                        </Grid>
                        <Grid item xs={12}>
                            <StyledCard>
                                {employeeData.length > 0 ? (
                                    <Stack sx={{ height: 'calc(100vh - 350px)', mt: 1 }}>
                                        <ErrorBoundary>
                                            <DataGridPro
                                                density={'compact'}
                                                columns={columns}
                                                rows={employeeData.map(employee => ({
                                                    id: employee.deviceToken,
                                                    workplace: employee.workplace,
                                                    operationUnit: employee.operationUnit,
                                                    line: employee.line,
                                                    stop: employee.stop,
                                                    employeeName: employee.workerName,
                                                    employeeID: employee.workerId,
                                                    arrivalTime: new Date(employee.departureAt),
                                                    shift: employee.shift,
                                                    language: employee.language,
                                                }))}
                                                rowHeight={35}
                                                checkboxSelection
                                                rowSelectionModel={tokensFromWorkerList}
                                                hideFooter={true}
                                                onRowSelectionModelChange={e => {
                                                    const selectedToken: string[] = [];
                                                    e.forEach(row => {
                                                        selectedToken.push(row.toString());
                                                    });
                                                    setTokensFromWorkerList(selectedToken);
                                                }}
                                                localeText={huHU.components.MuiDataGrid.defaultProps.localeText}
                                                unstable_headerFilters
                                            />
                                        </ErrorBoundary>
                                    </Stack>
                                ) : (
                                    <Grid
                                        container
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: 'calc(100vh - 350px)',
                                            width: '100%',
                                        }}>
                                        <Grid item>
                                            <Typography variant="h6">
                                                {loading ? (
                                                    <Stack direction="row" spacing={2} alignItems="center">
                                                        <CircularProgress
                                                            sx={{
                                                                '--CircularProgress-size': '60px',
                                                            }}
                                                        />
                                                        <Typography variant="h6">Adatok betöltése...</Typography>
                                                    </Stack>
                                                ) : (
                                                    'Nincs megjeleníthető adat!'
                                                )}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                            </StyledCard>
                        </Grid>
                    </Grid>
                </Grid>
            </SecuredLayout>
        </LocalizationProvider>
    );
}
