import { Button, Grid, TextField } from '@mui/material';
import { useState } from 'react';
import { useAuth, useProvideSnackBar } from '../../hooks';
import { StyledCard } from '../Layout/styles';
import { Send } from '@mui/icons-material';

const FIREBASE_API_ENDPOINT = (window as any).FIREBASE_API_URL;

type SendNotificationProps = {
    registrationTokens: Set<string>;
    setRegistrationTokens: (registrationTokens: Set<string>) => void;
    setSelectedCompanies: (selectedCompanies: string[]) => void;
};

export default function SendNotification({
                                             registrationTokens,
                                             setRegistrationTokens,
                                             setSelectedCompanies,
                                         }: SendNotificationProps) {
    const { user } = useAuth();
    const { showSuccess, showError, showResponseError } = useProvideSnackBar();
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');

    const validateNotificationData = () => {
        if (title === '') {
            showError('Hiányzó cím');
            return false;
        }
        if (body === '') {
            showError('Hiányzó üzenet');
            return false;
        }
        if (registrationTokens.size === 0) {
            showError('Hiányzó címzett');
            return false;
        }
        return true;
    };

    const fetchSendNotification = async () => {
        if (!validateNotificationData()) {
            return;
        }
        try {
            const response = await fetch(`${FIREBASE_API_ENDPOINT}/v1/notification/`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify({
                    registrationTokens: Array.from(registrationTokens),
                    body: body,
                    title: title,
                }),
            });
            if (!response.ok) {
                showResponseError(response);
            } else {
                showSuccess('Értesítés sikeresen elküldve.');
                setTitle('');
                setBody('');
                setRegistrationTokens(new Set<string>());
                setSelectedCompanies([]);
            }
        } catch (error) {
            showError('Értesítés küldése sikertelen.');
        }
    };

    return (
        <StyledCard>
            <Grid container spacing={1} p={2}>
                <Grid xs={12} py={2}>
                    <TextField
                        id="title"
                        label="Cím"
                        variant="outlined"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                    />
                </Grid>
                <Grid xs={12} py={2}>
                    <TextField
                        id="body"
                        label="Üzenet"
                        variant="outlined"
                        value={body}
                        onChange={e => setBody(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        multiline
                        rows={2}
                    />
                </Grid>
                <Grid xs={12}>
                    <Button variant="contained" color="primary" onClick={fetchSendNotification} fullWidth endIcon={<Send/>}>
                        Küldés
                    </Button>
                </Grid>
            </Grid>
        </StyledCard>
    );
}
